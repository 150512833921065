<template>
    <layout>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{ $t('menuitems.prices') }}</h4>
            </div>
            <div class="card-body bg-light py-5">
                <div class="row justify-content-center" v-if="!loading && prices">
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-sm-6 col-xl-6">
                                <div class="card pricing-box">
                                    <div class="card-body p-4">
                                        <div class="pt-2 pb-4">
                                            <h2 class="text-center">
                                                <span>{{ prices.sms_hu }}</span><sup class="ms-1"><small>{{ $t('pricing.ft') }}</small></sup>
                                                <span class="mx-1">/</span>
                                                <span class="font-size-13 text-muted">{{ $t('pricing.sms') }}</span>
                                            </h2>
                                        </div>

                                        <div>
                                            <h5 class="mb-3 text-center">{{ $t('pricing.domestic_messaging') }}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-xl-6">
                                <div class="card pricing-box">
                                    <div class="card-body p-4">
                                        <div class="pt-2 pb-4">
                                            <h2 class="text-center">
                                                <span>{{ prices.sms_abr }}</span><sup class="ms-1"><small>{{ $t('pricing.ft') }}</small></sup>
                                                <span class="mx-1">/</span>
                                                <span class="font-size-13 text-muted">{{ $t('pricing.sms') }}</span>
                                            </h2>
                                        </div>

                                        <div>
                                            <h5 class="mb-3 text-center">{{ $t('pricing.foreign_messaging') }}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end row -->
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../layouts/table";
import {mapGetters} from "vuex";

export default {
    name: "index",

    components: { Layout },

    computed: {
        ...mapGetters({
            prices: 'price/prices',
            loading: 'price/loading'
        })
    },

    created() {
        document.title = this.setTitle(this.$t('menuitems.prices'));
        this.$store.dispatch('price/fetch')
    }
}
</script>

<style scoped>

</style>
